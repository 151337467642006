import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import EventsHeader from "../components/EventsHeader";
import Footer from "../components/Footer";
import EventsList from "../components/EventsList";

const EventsPage = () => {
    return (
        <Layout pageTitle="Events | LUTH">
            <NavOne />
            <EventsHeader title="" />
            <EventsList />
            <Footer />
        </Layout>
    );
};

export default EventsPage;
